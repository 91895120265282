<script>
import ajax from "@/util/ajax.js";
export default {
  name: "MainMenu",
  inheritAttrs: false,
  props: {
    structure: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      scrollerHeight: 0,
      isOpen: false,
      selectedCategory: null,
      product_banners:null,
    };
  },
  computed: {
    structureById() {
      return this.structure.reduce((result, item) => {
        result[item["id"]] = item;
        return result;
      }, {});
    },
    currentCategory() {
      if (this.selectedCategory == null) {
        return null;
      }

      return this.structureById[this.selectedCategory] || null;
    },
  },
  methods: {
    selectCategory(id) {
      if (!id) {
        this.close();
        return;
      }

      if (this.closeTimer) {
        clearTimeout(this.closeTimer);
        this.closeTimer = null;
      }

      this.selectedCategory = id;
      this.isOpen = true;
      document.body.classList.add("noscroll");
      this.updateHeight();
    },
    close() {
      this.isOpen = false;
      this.closeTimer = setTimeout(() => {
        this.selectedCategory = null;
        this.closeTimer = null;
      }, 310);

      document.body.classList.remove("noscroll");
    },
    updateHeight() {
      this.scrollerHeight =
        window.innerHeight - this.$el.getBoundingClientRect().top - 41 + "px";
    },
    async loadbanners(){

        const result = await ajax("/ajax/content/get_banner_products.php", {}).then()
        console.log("result=",result);

        if (
                result &&
                result.result &&
                result.result === "success" &&
                result.products
        ) {
          this.product_banners = [...result.products];

          console.log("Products banner=",this.product_banners);
        }

      },
  },
  mounted() {

    this.loadbanners();

    window.addEventListener("resize", () => {
      if (this.isOpen) {
        this.updateHeight();
      }
    });
  },
};
</script>

<template>
  <div class="main-menu-wrapper" @mouseleave="close">
    <nav class="main-menu">
      <slot
        :isOpen="isOpen"
        :selectedCategory="selectedCategory"
        :selectCategory="selectCategory"
      ></slot>
    </nav>
    <div class="main-menu-popup" :class="{ open: isOpen }">
      <div class="main-menu-overlay" @click="close" @mouseenter="close"></div>
      <div class="scroller" :style="{ 'max-height': scrollerHeight }">
        <div class="main-menu-popup-content">
          <nav
            v-if="currentCategory && currentCategory.titles"
            class="column-1"
          >
            <ul class="nav-list">
              <li
                v-for="item in currentCategory.titles"
                :key="item.id"
                class="nav-item"
              >
                <a class="nav-link" :href="item.url">{{ item.title }}</a>
              </li>
            </ul>
          </nav>
          <div v-if="currentCategory" class="column-2">
            <div
              v-if="
                currentCategory.children && currentCategory.children.length > 0
              "
              class="nav-block"
            >
              <div class="nav-title">{{ currentCategory.title }}</div>
              <nav class="nav">
                <ul class="nav-list">
                  <li
                    v-for="item in currentCategory.children"
                    :key="item.id"
                    class="nav-item"
                  >
                    <a class="nav-link" :href="item.url">{{ item.title }}</a>
                  </li>
                </ul>
              </nav>
            </div>

            <template v-if="currentCategory.groups">
              <div
                v-for="group in currentCategory.groups"
                :key="group.id"
                class="nav-block"
              >
                <div class="nav-title">{{ group.title }}</div>
                <nav class="nav">
                  <ul class="nav-list">
                    <li
                      v-for="item in group.items"
                      :key="item.id"
                      class="nav-item"
                    >
                      <a class="nav-link" :href="item.url">{{ item.title }}</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </template>
          </div>
          <div
            v-if="currentCategory && currentCategory.banners"
            class="banners"
          >
            <template
              v-for="banner in currentCategory.banners"
              :key="banner.id"
            >


                <template v-for="product in product_banners"  :key="product.id">
                  <div v-if="product.banner_id===banner.id"
                   class="product-tiles"
                        :class="'columns-2'"
                  >
                    <div
                      class="product-tile-wrap product-banner" style="width: 75% !important;" >

                      <product-tile-banner
                              :product="product"
                              :categurl="baseUrl"
                              :viewtype="1"

                      >
                      </product-tile-banner>

                    </div>
                  </div>
                </template>


            </template>



          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.main-menu-wrapper {
  position: relative;
  height: 40px;
}

.main-menu-popup {
  position: absolute;
  left: 0;
  right: 0;
  top: 40px;
  background: #fff;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s, opacity 0.3s;

  .main-menu-overlay {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 0;
    background: $color-overlay;
    transition: bottom 0s 0.3s, height 0s 0.3s;
  }

  &.open {
    visibility: visible;
    opacity: 1;

    .main-menu-overlay {
      bottom: -100vh;
      height: 100vh;
      transition: bottom 0s, height 0s;
    }
  }
}
.scroller {
  overflow-x: hidden;
  overflow-y: auto;
}

.main-menu-popup-content {
  @include flex-row;

  padding: 40px var(--page-margin) 50px var(--page-margin);
}

.column-1,
.column-2 {
  position: sticky;
  top: 20px;
  flex-grow: 1;
  flex-shrink: 1;

  .nav-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .nav-item {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.column-1 {
  .nav-link {
    font-weight: 500;
    font-size: 14px;
    color: $color-text;
    text-transform: uppercase;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.column-2 {
  columns: 2;
  column-gap: 30px;

  .nav-block {
    margin-bottom: 40px;
    break-inside: avoid-column;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .nav-title {
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    color: $color-text;
    border-bottom: 1px solid $color-border;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  .nav-link {
    font-size: 16px;
    color: $color-text;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.banners {
  @include flex-row;

  align-self: stretch;

  margin-left: 50px;
  /*
  flex-grow: 1;
  flex-shrink: 1;
  */

  align-items: flex-start;
  justify-content: flex-end;
}

.banner {
  margin-right: 40px;

  position: sticky;
  top: 40px;

  &:last-child {
    margin-right: 0;
  }
}

@media (max-width: 1600px) {
  .column-2 {
    columns: 1;
  }
}

@media (max-width: 1600px) {
  .column-1,
  .column-2 {
    flex-grow: 1;
    flex-shrink: 1;
    width: 20%;
  }

  .banners {
    flex-grow: 1;
    flex-shrink: 1;
    width: 60%;
  }

  .banner {
    flex-grow: 1;
    flex-shrink: 1;
    width: 50%;

    &::v-deep img {
      display: block;
      width: 100%;
    }
  }
}
</style>
