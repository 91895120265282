<script>
  import combineProduct from "@/util/combineProduct.js";
  import findProductInCart from "@/util/findProductInCart.js";
  import {mapActions, mapState} from "vuex";

  export default {
  name: "ProductTile",
  props: {
    product: {
      type: Object,
      required: true,

    },
    categurl: {
      type: String,
      default: "",
    },

    filter: {
      type: Object,
      default: () => {
        return {};
      },
    },
    colorIds: {
      type: Object,
      default: () => {
        return {};
      },
    },
    initalColorUuid: {
      type: String,
      default: "",
    },
    viewtype: {
      type: Number,
      default: 0,
    },

  },
  data() {
    let initalColorIndex = 0;
    if (this.initalColorUuid) {
      for (let index in this.product.colors) {
        const color = this.product.colors[index];
        if (color.uuid === this.initalColorUuid) {
          initalColorIndex = index;
          break;
        }
      }
    }

    return {
      currentColorIndex: initalColorIndex,
      loading: false,
    };
  },
  computed: {
    filteredColors() {
      const colors = this.product.colors || [];
      return colors.filter((color) => this.filterColor(color));
    },
    currentColor() {
      return this.filteredColors[this.currentColorIndex];
    },
    currentColorUrl() {
      return (
        this.product.url +
        (this.currentColorIndex > 0 ? "/color/" + this.currentColor.uuid : "")
      );
    },
    canBeBought() {
      return this.currentColor && !this.currentColor.not_available && this.currentColor.price > 0;
    },
    isInFavorites() {
      if (this.currentColor && this.currentColor.uuid) {
        const favoritesId = this.product.id + ":" + this.currentColor.uuid;
        return this.favorites.indexOf(favoritesId) !== -1;
      } else {
        // Обработка случая, когда this.currentColor или this.currentColor.uuid не определены
        //console.error("this.currentColor or this.currentColor.uuid is undefined");
        return false; // или другая логика по умолчанию
      }
    },
    ...mapState(["cart", "favorites"]),
  },
  watch: {
    filteredColors: {
      deep: true,
      handler() {
        this.currentColorIndex = 0;
      },
    },
  },
  methods: {

    processUrl(url) {
      // Замена "/imagine/original/" на "/cache/imagine-webp/"
      let processedUrl = url ? url.replace(/\/imagine\/original\//, "/cache/imagine-webp/") : url;

      // Проверка, определено ли значение processedUrl перед вызовом метода endsWith
      if (processedUrl && processedUrl.endsWith(".jpg")) {
        processedUrl = processedUrl.replace(".jpg", ".jpg.webp");
      }

      return processedUrl;
    },
    parseColorsList(list) {
      return list.split(",").map((item) => this.colorIds[item.trim()]);
    },
    filterColor(color) {
      if (this.filter && this.filter.price) {
        const price = this.filter.price
          .split(",")
          .map((value) => parseFloat(value));
        if (color.price < price[0] || color.price > price[1]) {
          return false;
        }
      }

      if (
        this.filter &&
        this.filter.availability &&
        this.filter.availability === "in-stock"
      ) {
        if (color.not_available) {
          return false;
        }
      }

      if (this.filter && this.filter.colors) {
        const filterColors = this.parseColorsList(this.filter.colors);
        if (!filterColors.includes(color.color_group)) {
          return false;
        }
      }

      return true;
    },
    setCurrentColor(index) {
      if (this.currentColorIndex != index) {
        this.loading = true;
      }

     this.currentColorIndex = index;

      // Проверяем, что есть текущий цвет и у него есть свойство cover_sources
      if (this.currentColor && this.currentColor.cover_sources) {
        // Создаем новый массив с обновленными srcset
        // Обновляем свойство cover_sources в текущем цвете
        //this.$set(this.currentColor, 'cover_sources', updatedCoverSources);
        this.currentColor['cover_sources'] = this.currentColor.cover_sources.map((source) => {
          // Замена "/imagine/original/" на "/cache/imagine-webp/"
          let updatedSrcset = source.srcset.replace(/\/imagine\/original\//, "/cache/imagine-webp/");

          // Если srcset заканчивается на ".jpg", замена на ".jpg.webp"
          if (updatedSrcset.endsWith(".jpg")) {
            updatedSrcset = updatedSrcset.replace(".jpg", ".jpg.webp");
          }

          return {
            ...source,
            srcset: updatedSrcset,
          };
        });
      }

    },
    openFastView() {
      this.$root.openProductFastView(
        parseInt(this.product.id),
        this.currentColor.uuid
      );
    },
    isProductInCart(productId, colorId) {
      return findProductInCart(this.cart, productId, colorId) !== null;
    },
    addToFavorites() {
      const favoritesId = this.product.id + ":" + this.currentColor.uuid;
      this.addProductToFavorites(favoritesId);
    },
    removeFromFavorites() {
      const favoritesId = this.product.id + ":" + this.currentColor.uuid;
      const index = this.favorites.indexOf(favoritesId);
      this.removeProductFromFavorites(index);
    },
    toggleInFavorites() {
      if (this.isInFavorites) {
        this.removeFromFavorites();
      } else {
        this.addToFavorites();
      }
    },
    buy() {
      this.$store.dispatch(
        "addProductToCart",
        combineProduct(this.product, this.currentColor)
      );
    },
    ...mapActions(["addProductToFavorites", "removeProductFromFavorites"]),
  },
  mounted() {
    this.$refs.tileImage.addEventListener("load", (/*event*/) => {
      this.loading = false;
    });

    // Проверяем, что есть текущий цвет и у него есть свойство cover_sources
    if (this.currentColor && Array.isArray(this.currentColor.cover_sources)) {
      // Обновляем свойство cover_sources в текущем цвете
      //this.$set(this.currentColor, 'cover_sources', updatedCoverSources);
      this.currentColor['cover_sources'] = this.currentColor.cover_sources.map((source) => {
        let updatedSrcset = source.srcset.replace(/\/imagine\/original\//, "/cache/imagine-webp/");

        if (updatedSrcset.endsWith(".jpg")) {
          updatedSrcset = updatedSrcset.replace(".jpg", ".jpg.webp");
        }

        return {
          ...source,
          srcset: updatedSrcset,
        };
      });
    }
    this.loading = true;

  },
};
</script>

<template>
  <div class="product-tile" >
    <div class="top" >

      <button
        v-if="!loading"
        type="button"
        class="favorites"
        :class="{ 'is-active': isInFavorites }"
        @click="toggleInFavorites"
      >
        <icon-favorites-30 />
      </button>

      <button
        v-if="!loading"
        type="button"
        class="preview"
        @click="openFastView"
      >
        <icon-search class="icon" />{{ $s("STR_QUICK_VIEW") }}
      </button>
      <a class="image-wrap" :href="currentColorUrl">
        <div
          v-if="product.stickers && product.stickers.length > 0"
          class="stickers"
        >
          <div
            v-for="sticker in product.stickers"
            class="sticker"
            :style="{ backgroundColor: sticker.color }"
          >
            {{ sticker.title }}
          </div>
        </div>
        <picture class="image-wrap2">
          <source
                  v-for="source in currentColor && currentColor.cover_sources ? currentColor.cover_sources : []"
                  :srcset="processUrl(source.srcset)"
                  :media="source.media"
                  :type="source.type"
                  :key="JSON.stringify(source)"
          />
          <img
              class="image"
              :src="currentColor && currentColor.cover_src ? currentColor.cover_src : '/images/product-default.svg'"
              ref="tileImage"
          />
        </picture>
        <div
          v-if="currentColor && currentColor.cover_src"
          class="loading"
          :class="{ show: loading }"
        ></div>
      </a>
      <div class="colors">
        <template v-for="(color, index) in filteredColors" :key="color.uuid">
          <button
            v-if="color.color_image && color.color_image != 'null'"
            type="button"
            class="color"
            :class="{ selected: currentColorIndex == index }"
            :style="{
              backgroundImage: 'url(/download/files/' + color.color_image + ')',
            }"
            :title="color.title"
            @click="setCurrentColor(index)"
          ></button>
          <button
            v-else
            type="button"
            class="color"
            :class="{ selected: currentColorIndex == index }"
            :style="{
              backgroundColor: color.color || '#000000',
            }"
            :title="color.title"
            @click="setCurrentColor(index)"
          ></button>
        </template>
      </div>
    </div>
    <a class="bottom" :href="currentColorUrl">
      <div class="series">{{ product.collection_title }} </div>
      <div class="title" v-if="product.page_id && [35,86,87,100,101,102,103,104,105,106,107,108,109, 152].indexOf(parseInt(product.page_id)) !== -1">
          {{ product.description }}

      </div>
      <div v-else >
          <div class="pageid" style="display: none;">{{product.page_id}}</div>
          {{ product.description }} {{ product.size_title }} | {{ product.size }} | {{ product.weight }}


      </div>
      <div class="prices">
        <span v-if="currentColor && currentColor.price" class="current-price">{{
          $moneyFormat(currentColor.price)
        }}</span>
                <span v-if="currentColor && currentColor.price_old" class="old-price">{{
          $moneyFormat(currentColor.price_old)
        }}</span>
      </div>
    </a>

    <div v-if="canBeBought" class="buy-wrapper">

    <button
        v-if="!isProductInCart(product.id, currentColor.uuid)"
        class="buy"
        @click="buy"
      >
        {{ $s("STR_BUY") }}
      </button>
      <button v-else class="buy" @click="buy">
        {{ $s("STR_ALREADY_IN_CART") }}
      </button>

    </div>

  </div>
</template>
