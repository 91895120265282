import "vue-select/dist/vue-select.css";
import "@/styles/styles.scss";

import { createApp, defineAsyncComponent } from "vue";
import { mapState } from "vuex";
import { nanoid } from "nanoid";
import PerfectScrollbar from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";

import StdLink from "@/components/StdLink.vue";
import TelLink from "@/components/TelLink.vue";
import BlackBanner from "@/components/BlackBanner.vue";
import SearchField from "@/components/SearchField.vue";
import MainMenu from "@/components/MainMenu.vue";
import BlockSlider from "@/components/BlockSlider.vue";
import ProductTile from "@/components/ProductTile.vue";
import ProductTileBanner from "@/components/ProductTileBanner.vue";
import ProductsScroll from "@/components/ProductsScroll.vue";
import LooksScroll from "@/components/LooksScroll.vue";
import SubscribeForm from "@/components/SubscribeForm.vue";
import FaqItem from "@/components/FaqItem.vue";
import FaqSearch from "@/components/FaqSearch.vue";
import PopupForm from "@/components/PopupForm.vue";
import TextArea from "@/components/TextArea.vue";
import TextField from "@/components/TextField.vue";
import PopupDialog from "@/components/PopupDialog.vue";

import store from "@/store/index.js";

const icons = import.meta.globEager("./components/icons/*.vue");

window.Application = window.Application || {};

function initApp() {
  const el = document.querySelector(".vue-root");
  if (!el) {
    return;
  }

  const popupDialog = document.createElement("popup-dialog");
  popupDialog.setAttribute("v-if", "currentDialog");
  popupDialog.setAttribute("v-bind:key", "currentDialog.id");
  popupDialog.setAttribute("v-bind:dialog", "currentDialog");
  popupDialog.setAttribute("v-on:close", "closeDialog");
  el.append(popupDialog);

  const formTransition = document.createElement("transition");
  formTransition.setAttribute("name", "fade-05");
  const popupForm = document.createElement("popup-form");
  popupForm.setAttribute("v-if", "isFormOpen");
  popupForm.setAttribute("v-bind:scheme", "popupFormScheme");
  popupForm.setAttribute("v-on:close", "closeForm");
  formTransition.append(popupForm);
  el.append(formTransition);

  const fastViewTransition = document.createElement("transition");
  fastViewTransition.setAttribute("name", "fade-05");
  const popupFastView = document.createElement("product-fast-view");
  popupFastView.setAttribute("v-if", "isProductFastViewOpen");
  popupFastView.setAttribute("v-bind:inital-product-id", "productInFastView");
  popupFastView.setAttribute("v-bind:inital-color-uuid", "colorInFastView");
  popupFastView.setAttribute("v-on:close", "closeProductFastView");
  fastViewTransition.append(popupFastView);
  el.append(fastViewTransition);

  const links = el.getElementsByTagName("a");
  for (let link of links) {
    if (!link.hasAttribute("is")) {
      link.setAttribute("is", "vue:std-link");
    }
  }

  const template = el.innerHTML;

  const app = createApp({
    components: {
      PopupDialog,
    },
    data() {
      return {
        dialogFifo: [],
        isFormOpen: false,
        popupFormScheme: {},
        isFiltersOpen: false,
        isProductFastViewOpen: false,
        productInFastView: null,
        colorInFastView: 0,
        blinkCart: false,
        isPopupCartOpen: false,
        isMobileMenuOpen: false,
        changeCartMode: false,
        changeCartModeIndex: null,
      };
    },
    watch: {
      somethingInCart(value, oldValue) {
        if (value && !oldValue) {
          this.blinkCart = true;
          setTimeout(() => {
            this.blinkCart = false;
          }, 2000);
        }
      },
    },
    computed: {
      currentDialog() {
        if (!this.dialogFifo && this.dialogFifo.length < 1) {
          return null;
        }

        return this.dialogFifo[0];
      },
      somethingInCart() {
        return this.cart.length > 0;
      },
      ...mapState(["cart"]),
    },
    methods: {
      openDialog(text, options, callback) {
        this.dialogFifo.push({
          id: nanoid(),
          text: text,
          options: options || {},
          callback: callback || null,
        });
      },
      closeDialog() {
        this.dialogFifo.shift();
      },
      closeForm() {
        this.isFormOpen = false;
        this.popupFormScheme = {};
      },
      openFilters() {
        this.isFiltersOpen = true;
      },
      closeFilters() {
        this.isFiltersOpen = false;
      },
      toggleFilters() {
        this.isFiltersOpen = !this.isFiltersOpen;
      },
      openFeedbackForm() {
        this.popupFormScheme = {
          title: this.$s("STR_CONTACT_US"),
          description: this.$s("STR_CONTACT_US_DESCRIPTION"),
          button: this.$s("STR_SEND"),
          submitUrl: "/ajax/forms/submit_feedback.php",
          fields: [
            {
              name: "email",
              label: this.$s("STR_EMAIL"),
              type: "email",
              required: true,
              default: "",
            },
            {
              name: "message",
              label: this.$s("STR_MESSAGE"),
              type: "area",
              required: true,
              default: "",
            },
          ],
        };

        this.isFormOpen = true;
      },
      open1ClickForm(product) {
        this.popupFormScheme = {
          title: "Купить в 1 клик",
          description: this.$s("STR_CONTACT_US_DESCRIPTION"),
          button: this.$s("STR_SEND"),
          submitUrl: "/ajax/forms/submit_1click.php",
          fields: [
            {
              name: "product",
              label: "",
              type: "hidden",
              required: true,
              default: product,
            },
            {
              name: "name",
              label: "Имя",
              type: "text",
              required: true,
              default: "",
            },
            {
              name: "phone",
              label: "Телефон",
              type: "tel",
              required: true,
              default: "",
            },
            {
              name: "message",
              label: this.$s("STR_MESSAGE"),
              type: "area",
              required: true,
              default: "",
            },
          ],
        };

        this.isFormOpen = true;
      },
      openProductFastView(product, color) {
        this.productInFastView = parseInt(product);
        this.colorInFastView = color;
        this.isProductFastViewOpen = true;
        this.changeCartMode = false;
        this.changeCartModeIndex = null;
      },
      closeProductFastView() {
        this.isProductFastViewOpen = false;
        this.productInFastView = null;
        this.colorInFastView = 0;
        this.changeCartMode = false;
        this.changeCartModeIndex = null;
      },
      openProductChange(index, product, color) {
        this.productInFastView = parseInt(product);
        this.colorInFastView = color;
        this.isProductFastViewOpen = true;
        this.changeCartMode = true;
        this.changeCartModeIndex = index;
      },
      togglePopupCart() {
        this.isPopupCartOpen = !this.isPopupCartOpen;
      },
      closePopupCart() {
        this.isPopupCartOpen = false;
      },
      toggleMobileMenu() {
        this.isMobileMenuOpen = !this.isMobileMenuOpen;
      },
      closeMobileMenu() {
        this.isMobileMenuOpen = false;
      },
    },
    mounted() {
      window.nativeAlert = window.alert;
      window.alert = (text) => {
        this.openDialog(text, {
          html: true,
          close: true,
          buttons: [],
        });
      };

      // Вычисление толщины скроллбара браузера
      setTimeout(() => {
        const outer = document.createElement("div");
        outer.style.visibility = "hidden";
        outer.style.overflow = "scroll";
        outer.style.msOverflowStyle = "scrollbar";
        document.body.appendChild(outer);
        const inner = document.createElement("div");
        outer.appendChild(inner);
        const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
        outer.parentNode.removeChild(outer);

        document.documentElement.style.setProperty(
          "--scrollbar-width",
          scrollbarWidth + "px"
        );
      }, 50);

      window.addEventListener("focus", () => {
        this.$store.dispatch("load");
      });

      function updateHeaderBottom() {
        const header = document.querySelector(".page-header");
        const headerBottom =
          header.getBoundingClientRect().top + header.offsetHeight;
        document.documentElement.style.setProperty(
          "--header-bottom",
          headerBottom + "px"
        );
      }

      window.addEventListener("scroll", updateHeaderBottom, { passive: true });
      updateHeaderBottom();

      this.$store.dispatch("load");
    },
    template: template,
  });

  app.use(store);
  app.use(PerfectScrollbar);

  app.directive("hscroll-edges", {
    mounted(element) {
      const hscroller = element.firstChild;
      const child = hscroller.firstChild;
      function updateScroll() {
        const scroll = hscroller.scrollLeft;
        const width = hscroller.offsetWidth;
        const childWidth = child.offsetWidth;

        if (scroll == 0) {
          element.classList.add("hscroll-begin");
        } else {
          element.classList.remove("hscroll-begin");
        }

        if (scroll == childWidth - width) {
          element.classList.add("hscroll-end");
        } else {
          element.classList.remove("hscroll-end");
        }
      }

      updateScroll();
      hscroller.addEventListener("scroll", updateScroll, { passive: true });
      hscroller.addEventListener("resize", updateScroll, { passive: true });
    },
  });

  app.mixin({
    methods: {
      $s(name) {
        return (window.cms_strings || {})[name] || "";
      },
    },
  });

  app.mixin({
    methods: {
      $moneyFormat(price) {
        return parseFloat(price).toLocaleString("ru") + " " + this.$s("STR_TG");
      },
    },
  });

  app.mixin({
    methods: {
      $numberFormat(value) {
        return parseFloat(value).toLocaleString("ru");
      },
    },
  });

  Object.entries(icons).forEach(([path, definition]) => {
    const componentName = path
      .split("/")
      .pop()
      .replace(/\.\w+$/, "");
    app.component(componentName, definition.default);
  });

  app.component("StdLink", StdLink);
  app.component("TelLink", TelLink);
  app.component("BlackBanner", BlackBanner);
  app.component("SearchField", SearchField);
  app.component("MainMenu", MainMenu);
  app.component("BlockSlider", BlockSlider);
  app.component("ProductTile", ProductTile);
  app.component("ProductTileBanner", ProductTileBanner);
  app.component("ProductsScroll", ProductsScroll);
  app.component("LooksScroll", LooksScroll);
  app.component("SubscribeForm", SubscribeForm);
  app.component("FaqItem", FaqItem);
  app.component("FaqSearch", FaqSearch);
  app.component("PopupForm", PopupForm);
  app.component("TextArea", TextArea);
  app.component("TextField", TextField);
  app.component(
    "ContactsMap",
    defineAsyncComponent(() => import("@/components/ContactsMap.vue"))
  );
  app.component(
    "CatalogFilters",
    defineAsyncComponent(() => import("@/components/CatalogFliters.vue"))
  );
  app.component(
    "CatalogOrder",
    defineAsyncComponent(() => import("@/components/CatalogOrder.vue"))
  );
  app.component(
    "VueSelect",
    defineAsyncComponent(() => import("vue-select"))
  );
  app.component(
    "ProductPage",
    defineAsyncComponent(() => import("@/components/ProductPage.vue"))
  );
  app.component(
    "TextExpander",
    defineAsyncComponent(() => import("@/components/TextExpander.vue"))
  );
  app.component(
    "SwitchBlock",
    defineAsyncComponent(() => import("@/components/SwitchBlock.vue"))
  );
  app.component(
    "ProductFastView",
    defineAsyncComponent(() => import("@/components/ProductFastView.vue"))
  );
  app.component(
    "PopupCart",
    defineAsyncComponent(() => import("@/components/PopupCart.vue"))
  );
  app.component(
    "CartView",
    defineAsyncComponent(() => import("@/components/CartView.vue"))
  );
  app.component(
    "CheckboxField",
    defineAsyncComponent(() => import("@/components/CheckboxField.vue"))
  );
  app.component(
    "RadioField",
    defineAsyncComponent(() => import("@/components/RadioField.vue"))
  );
  app.component(
    "AddressMap",
    defineAsyncComponent(() => import("@/components/AddressMap.vue"))
  );
  app.component(
    "SelectField",
    defineAsyncComponent(() => import("@/components/SelectField.vue"))
  );
  app.component(
    "PopupOtp",
    defineAsyncComponent(() => import("@/components/PopupOTP.vue"))
  );
  app.component(
    "FavoritesView",
    defineAsyncComponent(() => import("@/components/FavoritesView.vue"))
  );
  app.component(
    "LooksView",
    defineAsyncComponent(() => import("@/components/LooksView.vue"))
  );
  app.component(
    "UserSignup",
    defineAsyncComponent(() => import("@/components/UserSignup.vue"))
  );
  app.component(
    "UserSignin",
    defineAsyncComponent(() => import("@/components/UserSignin.vue"))
  );
  app.component(
    "UserForgot",
    defineAsyncComponent(() => import("@/components/UserForgot.vue"))
  );
  app.component(
    "UserProfileAccount",
    defineAsyncComponent(() => import("@/components/UserProfileAccount.vue"))
  );
  app.component(
    "UserProfilePassword",
    defineAsyncComponent(() => import("@/components/UserProfilePassword.vue"))
  );
  app.component(
    "UserProfileAddresses",
    defineAsyncComponent(() => import("@/components/UserProfileAddresses.vue"))
  );
  app.component(
    "UserProfileOrders",
    defineAsyncComponent(() => import("@/components/UserProfileOrders.vue"))
  );
  app.component(
    "PopupMobileMenu",
    defineAsyncComponent(() => import("@/components/PopupMobileMenu.vue"))
  );
  app.component(
    "ClearCart",
    defineAsyncComponent(() => import("@/components/ClearCart.vue"))
  );
  app.component(
    "CartRecomendations",
    defineAsyncComponent(() => import("@/components/CartRecomendations.vue"))
  );

  app.mount(el);
}

if (window.document.readyState == "loading") {
  window.document.addEventListener("DOMContentLoaded", initApp);
} else {
  initApp();
}
